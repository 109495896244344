body {
	margin:0;
	padding:0;
	-webkit-background-size: cover; 
	background-size: cover;
	background: #008082 no-repeat center fixed;
	background-repeat: no-repeat;
	font-size: 13px;
	line-height:normal;
    font-family: 'Arial', sans-serif;
}

.wrapper, .content {
	width: 800px;
	margin: auto;
	margin-top: 20px;
	background: #bfb8bf;
	border: 2px solid;
	border-color: #fff8ff #000000 #000000 #fff8ff;
	padding: 2px 0;
}
.content{
	text-align: justify;
}

.default_title, .post_title {
	padding: 2px 4px 3px 4px;
	position: relative;
	h1 {
		color: #bfb8bf;
		font-size: 14px;
		font-weight: bold;
		
	}
	a{
		text-decoration: none;
	}
	img {
		float: left;
		width: 14px;
		height: 14px;
		margin: 0 3px 0 0;
	}
}


.post_title {
	h1 {
		display: inline-block;

	}

	a {
		color: #000000;
	}

	.btn {
		background: #cccccc;
		width: 13px;
		height: 11px;
		float: right;
		border: 2px solid;
		border-color: #fff8ff #000000 #000000 #fff8ff;

		span {
			font-size: 11px;
			position: relative;
			left: 2px;
			top: -2px;
		}

		.fa-window-minimize {
			font-size: 10px;
			left: 1px;
		}

		.fa-window-maximize {
			font-size: 10px;
			left: 1px;
		}
	}

	.btn_max {
		margin: 0 3px 0 0;
	}

	.btn:active {
		width: 12px;
		height: 10px;
	}
}
ul.topbar{
	list-style: none;
	padding: 0 5px;
	margin: 2px 0px 3px 0;
	text-align : left;
	li {
		display: inline-block;
		margin: 0 5px 0 0;
	}
	a.button_topbar {
		color: #000000;
		margin: 0px -2px 0px 0px;
		padding: 0px 2px 0px 2px;
		border-top: 1px solid white;
		border-left: 2px solid white;
		border-right: 2px solid #858585;
		border-bottom: 1px solid #858585;
	}
	a:active{
		border-top: 0px solid white;
		border-left: 0px solid white;
		border-bottom: 0px solid #858585;
	}
}

.rainbow_text_animated {
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 6s ease-in-out infinite;
	background-size: 400% 100%;
	border-top: 1px solid white;
	font-weight: bold;
	padding: 0px 5px 1px 4px;
    border-left: 2px solid white;
    border-right: 3px solid #4e4e4e;
	border-bottom: 1px solid #4e4e4e;
}

.rainbow_text_animated:active {
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 6s ease-in-out infinite;
	background-size: 400% 100%;
	border-top: 0px solid white;
	border-left: 0px solid white;
	border-bottom: 0px solid #858585;
	border-right: 0px solid #858585;
}


@keyframes rainbow_animation {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}

.post_list, .tag_list, .post_content {
	background: #fff8ff;
	border-width: 2px;
	border-style: ridge groove groove ridge;
	border-color: #7f787f #fff8ff #fff8ff #7f787f;
}
.post_list {
	width: 70.2%;
	margin: 0 auto;
	min-height: 150px;
	max-height: 150px;
	overflow-y: scroll;
	display: inline-block;

	ul {
		list-style: none;
	}
	
	li {
		display: inline-block;
		margin: 10px;
		max-width: 16ch;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: center;
		vertical-align: top;
		

		img {
			display: block;
			margin: 0 auto;
			margin-bottom: 8px;
		}

		a {
			white-space: pre-line;
			text-decoration: none;
			color: #000000;
		}
	}
}
.tag_list {
	width: 28%;
	margin: 0 auto;
	min-height: 150px;
	max-height: 150px;
	overflow-y: scroll;
	display: inline-block;

	img {
		height: 16px;
		width: 16px;
		margin: -2px 5px;
	}

	a {
		text-decoration: none;
		color: #000000;
	}

	ul {
		li {
			max-width: 27ch;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
#tag-list {
    background:transparent url(/assets/img/pixel-alt.png) repeat-y 15px 0;
}
#tag-list li {
    padding:0 0 0 2px;
}
#tag-list li a {
    background: #fff8ff;
    display:block;
}
#tag-list li ul li {
    background:transparent url(/assets/img/pixel-alt.png) repeat-x 0 8px;
    margin:4px 0 4px 13px;
    padding:0 0 0 20px;
}
.post_total {
	.left, .right {
		border: 1px solid;
		border-color: #888888 #dadada #dadada #888888;
		display: inline-block;
		padding: 1px 3px;
	}
	.left {
		width: 33%;
		margin-left: 2px;
	}
	.right {
		width: 63%;
		margin-right: 2px;
		text-align: right;
	}
}
.post_content {
	position: relative;
	width: 98.5%;
	min-height: 600px;
	max-height: 600px;
	padding: 2px;
	margin: 0 auto;
	overflow-y: scroll;
	overflow-x: scroll;

	img {
		width: 100%; 
		height: 100%; 
		object-fit: contain; 
	}

	iframe{
		width: 100%;
		object-fit: contain; 
	}

	.badge-80{
		height: 20px;
		width: 80px;
	}
	.badge-100{
		height: 20px;
		width: 100px;
	}
	.badge-120{
		height: 20px;
		width: 120px;
	}
	.badge-150{
		height: 20px;
		width: 150px;
	}
	.badge-170{
		height: 20px;
		width: 170px;
	}
	.emoji{
		height: 15px;
		width: 15px;
	}

	.emoji-long{
		height: 20px;
		width: 70px;
		padding-bottom: -10px;
	}
	
	p {
		line-height: 150%;
		margin-bottom: 15px;
		margin-left:5%;
		margin-right:5%;
	}

	a {
		color: #0000ff;
		margin-bottom: 15px;
	}

	h1 {
		font-size: 20px;
		font-weight: 700;
		margin: 50px 0 10px 2%;
	}

	h2 {
		font-size: 18px;
		font-weight: 700;
		margin: 25px 0 30px 2%;
	}

	h3 {
		font-size: 16px;
		font-weight: 700;
		margin: 25px 0 30px 3%;
	}

	h4 {
		font-size: 16px;
		font-weight: 700;
		margin: 25px 0 0px 3%;
	}

	em {
		font-style: italic;
	}

	blockquote {
		padding: 0 0 0 10px;
		margin: 15px 50px;
		border-left: 8px solid #383838;
	}

	strong {
		font-weight: 700;
	}

	hr {
		border-top: 3px solid #333333;
		border-left: 0;
	}

	ul {
		list-style: square;
		margin: 0 0 15px 30px;
		margin-left:7%;
		margin-right:5%;
		li {
			padding: 5px;

			ul {
				margin: 10px 0 0 15px;

				li {
					padding: 5px;
				}
			}
		}
	}

	ol {
		list-style: decimal-leading-zero;
		margin: 0 0 15px 30px;

		li {
			padding: 5px;

			ul {
				margin: 10px 0 0 15px;

				li {
					padding: 5px;
				}
			}
		}
	}

	table, th, td {
		border: 1px solid #222222;
		padding: 5px 5px 5px 5px;
		margin: 0 auto;            
	}

	pre {
		margin-bottom: 15px;
	}
}
.hljs {
	background: #fff8ff;
}
.footer {
	font-size: 12px;
	margin: 15px 0 10px 0;

	p {
		text-align: center;

		a {
			color: #24244a;
		}
	}
}
.footer_page {
	float: right;
	padding: 0 3px 0 0;

	p {
		text-align: right;
		word-break: break-word;
	}
}

.img_404{
	max-width:350px;   
	display: block;
	margin-left: auto;
	margin-right: auto;
}

@media only screen and (max-width: 900px) {
    .content {
        width: 90%;
    }
}
@media only screen and (max-width: 700px) {
    .content {
        width: 90%;
    }
	.post_content {
		width: 98%;
	}
}
@media only screen and (max-width: 400px) {
	.post_content {
		width: 97%;
	}
}
@media only screen and (max-width: 810px) {
	.wrapper {
		width: 90%;
	}
	.post_total {
		.left {
			width: 37%;
		}
		.right {
			float: right;
			width: 60%;
		}
	}
}
@media only screen and (max-width: 750px) {
	.tag_list {
		width: 99%;
	}
	.post_list {
		width: 99%;
		float: left;
	}
	.post_total {
		.left {
			width: 36%;
		}
		.right {
			float: right;
			width: 60%;
		}
	}
}
@media only screen and (max-width: 610px) {
	.tag_list {
		width: 98.7%;
	}
	.post_list {
		width: 98.7%;
		float: left;
	}
	.post_total {
		.left {
			width: 35.7%;
		}
	}
}
@media only screen and (max-width: 520px) {
	.post_total {
		.left {
			width: 34.5%;
		}
	}
}
@media only screen and (max-width: 410px) {
	.post_total {
		.left {
			width: 34%;
		}
	}
}
@media only screen and (max-width: 375px) {
	.post_total {
		.left {
			width: 33%;
		}
	}
}
::-webkit-scrollbar {
      width: 16px;
} /* this targets the default scrollbar (compulsory) */
::-webkit-scrollbar-track {
	background-image: url("/assets/img/track.png");
	background-repeat: repeat-x repeat-y;
} /* the new scrollbar will have a flat appearance with the set background color */
 
::-webkit-scrollbar-thumb {
	background-color: #cccccc;
	border-width: 2px;
	border-style: groove ridge ridge groove;
	border-color: #fff8ff #000000 #000000 #fff8ff;
} /* this will style the thumb, ignoring the track */
 
::-webkit-scrollbar-button {
	background-color: #cccccc;
	border-width: 2px;
	border-style: groove ridge ridge groove;
	border-color: #fff8ff #000000 #000000 #fff8ff;
	background-position: center center;
	background-repeat: no-repeat;

	&:vertical:decrement {
		background-image: url("/assets/img/up.png");
	}

	&:vertical:increment {
		background-image: url("/assets/img/down.png");
	}

	&:horizontal:decrement {
		background-image: url("/assets/img/left.png");
	}

	&:horizontal:increment {
		background-image: url("/assets/img/right.png");
	}

	&:active {
		background-position: 1px 2px;
	}
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */
 
::-webkit-scrollbar-corner {
	background-color: #cacaca;
} /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
